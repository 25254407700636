import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, FormControl, FormLabel, Input, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import axios from "axios";

const VoterInfo = ({ isOpen, onClose, categoryId, nominationId }) => {
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef()
    const toast = useToast();
    const [voteInfo, setVoterInfo] = React.useState({});

    useEffect(() => {
        const voterData = JSON.parse(localStorage.getItem('voterData'));
        if (voterData) {
            setVoterInfo(voterData);
        } else {
            setVoterInfo({
                name: "",
                phone: "",
                email: ""
            });
        }
    }, [isOpen])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVoterInfo((prev) => ({ ...prev, [name]: value }));
    }

    const validateVoterInfo = () => {
        const { name, phone, email } = voteInfo;
        if (name && phone && email) {
            return true;
        }
        return false;
    }

    const submitVote = () => {
        if (validateVoterInfo()) {
            localStorage.setItem('voterData', JSON.stringify(voteInfo));
            axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/vote`, {
                categoryId: categoryId ? categoryId : 1,
                nominationId: nominationId ? nominationId : 7,
                ...voteInfo
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            ).then((response) => {
                toast({
                    title: "Vote submitted successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                onClose();
            }).catch((error) => {
                if (error.response && error.response.data) {
                    toast({
                        title: error.response.data.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    })
                }
                console.error(error);
            })
        } else {
            toast({
                title: "Please enter all the details",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return;
        }
    }

    return (
        <>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
                width={{ base: "90vw", md: "50vw", lg: "30vw" }}
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Please Enter Your Details</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <FormControl isRequired>
                            <FormLabel>Name</FormLabel>
                            <Input value={voteInfo['name']} onChange={handleChange} name="name" placeholder="Name" />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Mobile</FormLabel>
                            <Input value={voteInfo['phone']} onChange={handleChange} name="phone" placeholder="Mobile" />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input value={voteInfo['email']} onChange={handleChange} name="email" placeholder="Email" />
                        </FormControl>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button colorScheme="blue" ref={cancelRef} onClick={submitVote}>
                            Submit
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}

export default VoterInfo;