import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import VotingCard from './VotingCard';

const VotingRow = ({ awardCategory, nominees, shortcode, onViewAllClick }) => {
  return (
    <Box mb="4" marginX={{base: 10, md: 100}}>
      <Flex justifyContent="space-between" alignItems="center" mb="3">
        <Heading as="h2" size={{base:'sm', md: 'md'}}>
          {awardCategory}
        </Heading>
        <Button variant="outline" colorScheme='messenger' onClick={() => onViewAllClick(shortcode)}>
          View All
        </Button>
      </Flex>
      <Flex gap={10} flexWrap='nowrap' overflowX="auto">
        {nominees && nominees.length ? (
          nominees.map((nominee) => (
            <VotingCard
              key={nominee.id}
              imageSrc={nominee.photo}
              nomineeName={nominee.firstName + " " + nominee.lastName}
              designation={nominee.designation}
              awardCategory={awardCategory}
              onVoteClick={nominee.onVoteClick}
              shortcode={shortcode}
              nomineeShortCode={nominee.shortcode}
            />
          ))
        ): (
          <Text size="lg">
            No Nominees Found
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default VotingRow;