export const awardCategories = [
    {
        name: "Inspirational Leader",
        shortcode: 'inspirational-leader',
        femaleSpecific: false,
        icon: require('../assets/images/il-icon.png'),
        description: 'An outstanding individual to comprehend the transformative power of leadership that uplifts, empowers, and inspires. Who also possesses a rare blend of compassion and vision which leads to anticipate future shared success. And invests time and attention in strengthening the skills of others around them.'
    },
    {
        name: "Rising Star",
        shortcode: 'rising-star',
        femaleSpecific: false,
        icon: require('../assets/images/rs-icon.png'),
        description: 'A ray of hope and innovation to people who have demonstrated outstanding dedication, a thirst for education, and a willingness to go above and beyond the call of duty. A recognition given to those who have a desire to learn spread, encouraging those around you.'
    },
    {
        name: "Community Contributor",
        shortcode: 'community-contributor',
        femaleSpecific: false,
        icon: require('../assets/images/cc-icon.png'),
        description: 'Has been the driving force behind various initiatives that have significantly benefited the community. The influence has been transformative, leaving an indelible mark on the lives of individuals who have been blessed by generosity and dedication. The sense of community, and the significant impact that one individual can have on the lives of many.'
    },
    {
        name: "Innovative Startup",
        shortcode: 'innovative-startup',
        femaleSpecific: false,
        icon: require('../assets/images/is-icon.png'),
        description: 'The voyage demonstrates the power of great dreams and daring to disrupt the status quo. Startups are the forerunners of transformative ideas, and innovation is the lifeblood of progress.  Has adopted this philosophy, reinterpreted it, and grown into a bright example of what happens when passion, vision, and resolve come together.'
    },
    {
        name: 'Woman Founder',
        shortcode: 'woman-founder',
        femaleSpecific: true,
        icon: require('../assets/images/wf-icon.png'),
        description: 'The journey involves more than just constructing a successful business. It is about paving the way for others to follow, removing preconceptions, and creating an atmosphere in which every woman may succeed.  A monument to individuals who not only broke glass ceilings but also paved the road for others to follow.'
    }
];


export const states = [
    "Andaman Nicobar",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra Nagar Haveli and Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
];

export const categoryIcons  = {
    'inspirational-leader': require('../assets/images/il-icon.png'),
    'rising-star': require('../assets/images/rs-icon.png'),
    'community-contributor': require('../assets/images/cc-icon.png'),
    'innovative-startup': require('../assets/images/is-icon.png'),
    'woman-founder': require('../assets/images/wf-icon.png')
}

// list all the districts in each state
export const districts = {
    "Andaman Nicobar": [
        "Nicobar",
        "North Middle Andaman",
        "South Andaman"
    ],
    "Andhra Pradesh": [
        "Anantapur",
        "Chittoor",
        "East Godavari",
        "Alluri Sitarama Raju",
        "Anakapalli",
        "Annamaya",
        "Bapatla",
        "Eluru",
        "Guntur",
        "Kadapa",
        "Kakinada",
        "Konaseema",
        "Krishna",
        "Kurnool",
        "Manyam",
        "N T Rama Rao",
        "Nandyal",
        "Nellore",
        "Palnadu",
        "Prakasam",
        "Sri Balaji",
        "Sri Satya Sai",
        "Srikakulam",
        "Visakhapatnam",
        "Vizianagaram",
        "West Godavari"
    ],
    "Arunachal Pradesh": [
        "Anjaw",
        "Changlang",
        "Dibang Valley",
        "East Kameng",
        "East Siang",
        "Kamle",
        "Kra Daadi",
        "Kurung Kumey",
        "Lepa Rada",
        "Lohit",
        "Longding",
        "Lower Dibang Valley",
        "Lower Siang",
        "Lower Subansiri",
        "Namsai",
        "Pakke Kessang",
        "Papum Pare",
        "Shi Yomi",
        "Siang",
        "Tawang",
        "Tirap",
        "Upper Siang",
        "Upper Subansiri",
        "West Kameng",
        "West Siang"
    ],
    "Assam": [
        "Baksa",
        "Barpeta",
        "Bongaigaon",
        "Cachar",
        "Charaideo",
        "Chirang",
        "Darrang",
        "Dhemaji",
        "Dhubri",
        "Dibrugarh",
        "Dima Hasao",
        "Goalpara",
        "Golaghat",
        "Hailakandi",
        "Jorhat",
        "Kamrup Metropolitan",
        "Kamrup Rural",
        "Karbi Anglong",
        "Karimganj",
        "Kokrajhar",
        "Lakhimpur",
        "Majuli",
        "Morigaon",
        "Nagaon",
        "Nalbari",
        "Sivasagar",
        "Sonitpur",
        "South Salmara-Mankachar",
        "Tinsukia",
        "Udalguri",
        "West Karbi Anglong"
    ],
    "Bihar": [
        "Araria",
        "Arwal",
        "Aurangabad",
        "Banka",
        "Begusarai",
        "Bhagalpur",
        "Bhojpur",
        "Buxar",
        "Darbhanga",
        "East Champaran",
        "Gaya",
        "Gopalganj",
        "Jamui",
        "Jehanabad",
        "Kaimur",
        "Katihar",
        "Khagaria",
        "Kishanganj",
        "Lakhisarai",
        "Madhepura",
        "Madhubani",
        "Munger",
        "Muzaffarpur",
        "Nalanda",
        "Nawada",
        "Patna",
        "Purnia",
        "Rohtas",
        "Saharsa",
        "Samastipur",
        "Saran",
        "Sheikhpura",
        "Sheohar",
        "Sitamarhi",
        "Siwan",
        "Supaul",
        "Vaishali",
        "West Champaran"
    ],
    "Chandigarh": [
        "Chandigarh"
    ],
    "Chhattisgarh": [
        "Balod",
        "Baloda Bazar",
        "Balrampur",
        "Bastar",
        "Bemetara",
        "Bijapur",
        "Bilaspur",
        "Dantewada",
        "Dhamtari",
        "Durg",
        "Gariaband",
        "Gaurela Pendra Marwahi",
        "Janjgir Champa",
        "Jashpur",
        "Kabirdham",
        "Kanker",
        "Khairagarh",
        "Kondagaon",
        "Korba",
        "Koriya",
        "Mahasamund",
        "Manendragarh",
        "Mohla Manpur",
        "Mungeli",
        "Narayanpur",
        "Raigarh",
        "Raipur",
        "Rajnandgaon",
        "Sakti",
        "Sarangarh Bilaigarh",
        "Sukma",
        "Surajpur",
        "Surguja"
    ],
    "Dadra Nagar Haveli and Daman and Diu": [
        "Dadra and Nagar Haveli",
        "Daman",
        "Diu"
    ],
    "Delhi": [
        "Central Delhi",
        "East Delhi",
        "New Delhi",
        "North Delhi",
        "North East Delhi",
        "North West Delhi",
        "Shahdara",
        "South Delhi",
        "South East Delhi",
        "South West Delhi",
        "West Delhi"
    ],
    "Goa": [
        "North Goa",
        "South Goa"
    ],
    "Gujarat": [
        "Ahmedabad",
        "Amreli",
        "Anand",
        "Aravalli",
        "Banaskantha",
        "Bharuch",
        "Bhavnagar",
        "Botad",
        "Chhota Udaipur",
        "Dahod",
        "Dang",
        "Devbhoomi Dwarka",
        "Gandhinagar",
        "Gir Somnath",
        "Jamnagar",
        "Junagadh",
        "Kheda",
        "Kutch",
        "Mahisagar",
        "Mehsana",
        "Morbi",
        "Narmada",
        "Navsari",
        "Panchmahal",
        "Patan",
        "Porbandar",
        "Rajkot",
        "Sabarkantha",
        "Surat",
        "Surendranagar",
        "Tapi",
        "Vadodara",
        "Valsad"
    ],
    "Haryana": [
        "Ambala",
        "Bhiwani",
        "Charkhi Dadri",
        "Faridabad",
        "Fatehabad",
        "Gurugram",
        "Hisar",
        "Jhajjar",
        "Jind",
        "Kaithal",
        "Karnal",
        "Kurukshetra",
        "Mahendragarh",
        "Nuh",
        "Palwal",
        "Panchkula",
        "Panipat",
        "Rewari",
        "Rohtak",
        "Sirsa",
        "Sonipat",
        "Yamunanagar"
    ],
    "Himachal Pradesh": [
        "Bilaspur",
        "Chamba",
        "Hamirpur",
        "Kangra",
        "Kinnaur",
        "Kullu",
        "Lahaul Spiti",
        "Mandi",
        "Shimla",
        "Sirmaur",
        "Solan",
        "Una"
    ],
    "Jammu Kashmir": [
        "Anantnag",
        "Bandipora",
        "Baramulla",
        "Budgam",
        "Doda",
        "Ganderbal",
        "Jammu",
        "Kathua",
        "Kishtwar",
        "Kulgam",
        "Kupwara",
        "Poonch",
        "Pulwama",
        "Rajouri",
        "Ramban",
        "Reasi",
        "Samba",
        "Shopian",
        "Srinagar",
        "Udhampur"
    ],
    "Jharkhand": [
        "Bokaro",
        "Chatra",
        "Deoghar",
        "Dhanbad",
        "Dumka",
        "East Singhbhum",
        "Garhwa",
        "Giridih",
        "Godda",
        "Gumla",
        "Hazaribagh",
        "Jamtara",
        "Khunti",
        "Koderma",
        "Latehar",
        "Lohardaga",
        "Pakur",
        "Palamu",
        "Ramgarh",
        "Ranchi",
        "Sahebganj",
        "Seraikela Kharsawan",
        "Simdega",
        "West Singhbhum"
    ],
    "Karnataka": [
        "Bagalkot",
        "Bangalore Rural",
        "Bangalore Urban",
        "Belgaum",
        "Bellary",
        "Bidar",
        "Chamarajanagar",
        "Chikkaballapur",
        "Chikkamagaluru",
        "Chitradurga",
        "Dakshina Kannada",
        "Davanagere",
        "Dharwad",
        "Gadag",
        "Kalaburagi",
        "Hassan",
        "Haveri",
        "Kodagu",
        "Kolar",
        "Koppal",
        "Mandya",
        "Mysore",
        "Raichur",
        "Ramanagara",
        "Shimoga",
        "Tumkur",
        "Udupi",
        "Uttara Kannada",
        "Vijayanagara",
        "Vijayapura",
        "Yadgir"
    ],
    "Kerala": [
        "Alappuzha",
        "Ernakulam",
        "Idukki",
        "Kannur",
        "Kasaragod",
        "Kollam",
        "Kottayam",
        "Kozhikode",
        "Malappuram",
        "Palakkad",
        "Pathanamthitta",
        "Thiruvananthapuram",
        "Thrissur",
        "Wayanad"
    ],
    "Ladakh": [
        "Kargil",
        "Leh"
    ],
    "Lakshadweep": [
        "Lakshadweep"
    ],
    "Madhya Pradesh": [
        "Agar Malwa",
        "Alirajpur",
        "Anuppur",
        "Ashoknagar",
        "Balaghat",
        "Barwani",
        "Betul",
        "Bhind",
        "Bhopal",
        "Burhanpur",
        "Chachaura",
        "Chhatarpur",
        "Chhindwara",
        "Damoh",
        "Datia",
        "Dewas",
        "Dhar",
        "Dindori",
        "Guna",
        "Gwalior",
        "Harda",
        "Hoshangabad",
        "Indore",
        "Jabalpur",
        "Jhabua",
        "Katni",
        "Khandwa",
        "Khargone",
        "Maihar",
        "Mandla",
        "Mandsaur",
        "Morena",
        "Nagda",
        "Narsinghpur",
        "Neemuch",
        "Niwari",
        "Pandhurna",
        "Panna",
        "Raisen",
        "Rajgarh",
        "Ratlam",
        "Rewa",
        "Sagar",
        "Satna",
        "Sehore",
        "Seoni",
        "Shahdol",
        "Shajapur",
        "Sheopur",
        "Shivpuri",
        "Sidhi",
        "Singrauli",
        "Tikamgarh",
        "Ujjain",
        "Umaria",
        "Vidisha"
    ],
    "Maharashtra": [
        "Ahmednagar",
        "Akola",
        "Amravati",
        "Aurangabad",
        "Beed",
        "Bhandara",
        "Buldhana",
        "Chandrapur",
        "Dhule",
        "Gadchiroli",
        "Gondia",
        "Hingoli",
        "Jalgaon",
        "Jalna",
        "Kolhapur",
        "Latur",
        "Mumbai City",
        "Mumbai Suburban",
        "Nagpur",
        "Nanded",
        "Nandurbar",
        "Nashik",
        "Osmanabad",
        "Palghar",
        "Parbhani",
        "Pune",
        "Raigad",
        "Ratnagiri",
        "Sangli",
        "Satara",
        "Sindhudurg",
        "Solapur",
        "Thane",
        "Wardha",
        "Washim",
        "Yavatmal"
    ],
    "Manipur": [
        "Bishnupur",
        "Chandel",
        "Churachandpur",
        "Imphal East",
        "Imphal West",
        "Jiribam",
        "Kakching",
        "Kamjong",
        "Kangpokpi",
        "Noney",
        "Pherzawl",
        "Senapati",
        "Tamenglong",
        "Tengnoupal",
        "Thoubal",
        "Ukhrul"
    ],
    "Meghalaya": [
        "East Garo Hills",
        "East Jaintia Hills",
        "East Khasi Hills",
        "Mairang",
        "North Garo Hills",
        "Ri Bhoi",
        "South Garo Hills",
        "South West Garo Hills",
        "South West Khasi Hills",
        "West Garo Hills",
        "West Jaintia Hills",
        "West Khasi Hills"
    ],
    "Mizoram": [
        "Aizawl",
        "Champhai",
        "Hnahthial",
        "Khawzawl",
        "Kolasib",
        "Lawngtlai",
        "Lunglei",
        "Mamit",
        "Saiha",
        "Saitual",
        "Serchhip"
    ],
    "Nagaland": [
        "Chumukedima",
        "Dimapur",
        "Kiphire",
        "Kohima",
        "Longleng",
        "Mokokchung",
        "Mon",
        "Niuland",
        "Noklak",
        "Peren",
        "Phek",
        "Shamator",
        "Tseminyu",
        "Tuensang",
        "Wokha",
        "Zunheboto"
    ],
    "Odisha": [
        "Angul",
        "Balangir",
        "Balasore",
        "Bargarh",
        "Bhadrak",
        "Boudh",
        "Cuttack",
        "Debagarh",
        "Dhenkanal",
        "Gajapati",
        "Ganjam",
        "Jagatsinghpur",
        "Jajpur",
        "Jharsuguda",
        "Kalahandi",
        "Kandhamal",
        "Kendrapara",
        "Kendujhar",
        "Khordha",
        "Koraput",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nayagarh",
        "Nuapada",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundergarh"
    ],
    "Puducherry": [
        "Karaikal",
        "Mahe",
        "Puducherry",
        "Yanam"
    ],
    "Punjab": [
        "Amritsar",
        "Barnala",
        "Bathinda",
        "Faridkot",
        "Fatehgarh Sahib",
        "Fazilka",
        "Firozpur",
        "Gurdaspur",
        "Hoshiarpur",
        "Jalandhar",
        "Kapurthala",
        "Ludhiana",
        "Malerkotla",
        "Mansa",
        "Moga",
        "Mohali",
        "Muktsar",
        "Pathankot",
        "Patiala",
        "Rupnagar",
        "Sangrur",
        "Shaheed Bhagat Singh Nagar",
        "Tarn Taran"
    ],
    "Rajasthan": [
        "Ajmer",
        "Alwar",
        "Anupgarh",
        "Balotra",
        "Banswara",
        "Baran",
        "Barmer",
        "Beawar",
        "Bharatpur",
        "Bhilwara",
        "Bikaner",
        "Bundi",
        "Chittorgarh",
        "Churu",
        "Dausa",
        "Deeg",
        "Dholpur",
        "Didwana Kuchaman",
        "Dudu",
        "Dungarpur",
        "Gangapur City",
        "Hanumangarh",
        "Jaipur",
        "Jaipur Rural",
        "Jaisalmer",
        "Jalore",
        "Jhalawar",
        "Jhunjhunu",
        "Jodhpur",
        "Jodhpur Rural",
        "Karauli",
        "Kekri",
        "Khairthal Tijara",
        "Kota",
        "Kotputli Behror",
        "Nagaur",
        "Neem ka Thana",
        "Pali",
        "Phalodi",
        "Pratapgarh",
        "Rajsamand",
        "Salumbar",
        "Sanchore",
        "Sawai Madhopur",
        "Shahpura",
        "Sikar",
        "Sirohi",
        "Sri Ganganagar",
        "Tonk",
        "Udaipur"
    ],
    "Sikkim": [
        "East Sikkim",
        "North Sikkim",
        "Pakyong",
        "Soreng",
        "South Sikkim",
        "West Sikkim"
    ],
    "Tamil Nadu": [
        "Ariyalur",
        "Chengalpattu",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kallakurichi",
        "Kanchipuram",
        "Kanyakumari",
        "Karur",
        "Krishnagiri",
        "Madurai",
        "Mayiladuthurai",
        "Nagapattinam",
        "Namakkal",
        "Nilgiris",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Ranipet",
        "Salem",
        "Sivaganga",
        "Tenkasi",
        "Thanjavur",
        "Theni",
        "Thoothukudi",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupattur",
        "Tiruppur",
        "Tiruvallur",
        "Tiruvannamalai",
        "Tiruvarur",
        "Vellore",
        "Viluppuram",
        "Virudhunagar"
    ],
    "Telangana": [
        "Adilabad",
        "Bhadradri Kothagudem",
        "Hanamkonda",
        "Hyderabad",
        "Jagtial",
        "Jangaon",
        "Jayashankar",
        "Jogulamba",
        "Kamareddy",
        "Karimnagar",
        "Khammam",
        "Komaram Bheem",
        "Mahabubabad",
        "Mahbubnagar",
        "Mancherial",
        "Medak",
        "Medchal Malkajgiri",
        "Mulugu",
        "Nagarkurnool",
        "Nalgonda",
        "Narayanpet",
        "Nirmal",
        "Nizamabad",
        "Peddapalli",
        "Rajanna Sircilla",
        "Ranga Reddy",
        "Sangareddy",
        "Siddipet",
        "Suryapet",
        "Vikarabad",
        "Wanaparthy",
        "Warangal",
        "Yadadri Bhuvanagiri"
    ],
    "Tripura": [
        "Dhalai",
        "Gomati",
        "Khowai",
        "North Tripura",
        "Sepahijala",
        "South Tripura",
        "Unakoti",
        "West Tripura"
    ],
    "Uttar Pradesh": [
        "Agra",
        "Aligarh",
        "Ambedkar Nagar",
        "Amethi",
        "Amroha",
        "Auraiya",
        "Ayodhya",
        "Azamgarh",
        "Baghpat",
        "Bahraich",
        "Ballia",
        "Balrampur",
        "Banda",
        "Barabanki",
        "Bareilly",
        "Basti",
        "Bhadohi",
        "Bijnor",
        "Budaun",
        "Bulandshahr",
        "Chandauli",
        "Chitrakoot",
        "Deoria",
        "Etah",
        "Etawah",
        "Farrukhabad",
        "Fatehpur",
        "Firozabad",
        "Gautam Buddha Nagar",
        "Ghaziabad",
        "Ghazipur",
        "Gonda",
        "Gorakhpur",
        "Hamirpur",
        "Hapur",
        "Hardoi",
        "Hathras",
        "Jalaun",
        "Jaunpur",
        "Jhansi",
        "Kannauj",
        "Kanpur Dehat",
        "Kanpur Nagar",
        "Kasganj",
        "Kaushambi",
        "Lakhimpur Kheri",
        "Kushinagar",
        "Lalitpur",
        "Lucknow",
        "Maharajganj",
        "Mahoba",
        "Mainpuri",
        "Mathura",
        "Mau",
        "Meerut",
        "Mirzapur",
        "Moradabad",
        "Muzaffarnagar",
        "Pilibhit",
        "Pratapgarh",
        "Prayagraj",
        "Raebareli",
        "Rampur",
        "Saharanpur",
        "Sambhal",
        "Sant Kabir Nagar",
        "Shahjahanpur",
        "Shamli",
        "Shravasti",
        "Siddharthnagar",
        "Sitapur",
        "Sonbhadra",
        "Sultanpur",
        "Unnao",
        "Varanasi"
    ],
    "Uttarakhand": [
        "Almora",
        "Bageshwar",
        "Chamoli",
        "Champawat",
        "Dehradun",
        "Haridwar",
        "Nainital",
        "Pauri",
        "Pithoragarh",
        "Rudraprayag",
        "Tehri",
        "Udham Singh Nagar",
        "Uttarkashi"
    ],
    "West Bengal": [
        "Alipurduar",
        "Bankura",
        "Birbhum",
        "Cooch Behar",
        "Dakshin Dinajpur",
        "Darjeeling",
        "Hooghly",
        "Howrah",
        "Jalpaiguri",
        "Jhargram",
        "Kalimpong",
        "Kolkata",
        "Malda",
        "Murshidabad",
        "Nadia",
        "North 24 Parganas",
        "Paschim Bardhaman",
        "Paschim Medinipur",
        "Purba Bardhaman",
        "Purba Medinipur",
        "Purulia",
        "South 24 Parganas",
        "Uttar Dinajpur"
    ]
};

export const dummyData = [
    {
        awardCategory: 'Rising Star',
        categoryShortCode: 'rising-star',
        nominees: [
            {
                id: 1,
                nomineeShortCode: 'aljo',
                imageSrc: null,
                nomineeName: 'Alice Johnson',
                designation: 'Junior Developer',
                about: 'Alice is a junior developer at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/rising-star',
                onVoteClick: () => console.log('Voted for Alice Johnson'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 2,
                nomineeShortCode: 'bosm',
                imageSrc: null,
                nomineeName: 'Bob Smith',
                designation: 'Software Engineer',
                about: 'Bob is a software engineer at a startup in Pune. He is passionate about building accessible and inclusive products. He is also a tech blogger and loves to share his knowledge with the community.',
                awardCategoryLink: '/award-category/rising-star',
                onVoteClick: () => console.log('Voted for Bob Smith'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 3,
                nomineeShortCode: 'chbr',
                imageSrc: null,
                nomineeName: 'Charlie Brown',
                designation: 'Web Developer',
                about: 'Charlie is a web developer at a startup in Pune. He is passionate about building accessible and inclusive products. He is also a tech blogger and loves to share his knowledge with the community.',
                awardCategoryLink: '/award-category/rising-star',
                onVoteClick: () => console.log('Voted for Charlie Brown'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 4,
                nomineeShortCode: 'diwi',
                imageSrc: null,
                nomineeName: 'Diana Williams',
                designation: 'Frontend Developer',
                about: 'Diana is a frontend developer at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/rising-star',
                onVoteClick: () => console.log('Voted for Diana Williams'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 5,
                nomineeShortCode: 'evma',
                imageSrc: null,
                nomineeName: 'Eva Martinez',
                designation: 'UX Designer',
                about: 'Eva is a UX designer at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/rising-star',
                onVoteClick: () => console.log('Voted for Eva Martinez'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            // Add more nominees...
        ],
    },
    {
        awardCategory: 'Community Contributor',
        categoryShortCode: 'community-contributor',
        nominees: [
            {
                id: 6,
                nomineeShortCode: 'frro',
                imageSrc: null,
                nomineeName: 'Frank Rodriguez',
                designation: 'Open Source Advocate',
                about: 'Frank is an open source advocate at a startup in Pune. He is passionate about building accessible and inclusive products. He is also a tech blogger and loves to share his knowledge with the community.',
                awardCategoryLink: '/award-category/community-contributor',
                onVoteClick: () => console.log('Voted for Frank Rodriguez'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 7,
                nomineeShortCode: 'grin',
                imageSrc: null,
                nomineeName: 'Grace Innovations',
                designation: 'Community Organizer',
                about: 'Grace Innovations is a community organizer at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/community-contributor',
                onVoteClick: () => console.log('Voted for Grace Innovations'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 8,
                nomineeShortCode: 'hete',
                imageSrc: null,
                nomineeName: 'Henry Tech Solutions',
                designation: 'Tech Evangelist',
                about: 'Henry Tech Solutions is a tech evangelist at a startup in Pune. He is passionate about building accessible and inclusive products. He is also a tech blogger and loves to share his knowledge with the community.',
                awardCategoryLink: '/award-category/community-contributor',
                onVoteClick: () => console.log('Voted for Henry Tech Solutions'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 9,
                imageSrc: null,
                nomineeShortCode: 'islo',
                nomineeName: 'Isabel Lopez',
                designation: 'Social Impact Volunteer',
                about: 'Isabel is a social impact volunteer at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/community-contributor',
                onVoteClick: () => console.log('Voted for Isabel Lopez'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 10,
                imageSrc: null,
                nomineeShortCode: 'jale',
                nomineeName: 'Jackie Lee',
                designation: 'Digital Rights Advocate',
                about: 'Jackie is a digital rights advocate at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/community-contributor',
                onVoteClick: () => console.log('Voted for Jackie Lee'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            // Add more nominees...
        ],
    },
    {
        awardCategory: 'Inspirational Leader',
        categoryShortCode: 'inspirational-leader',
        nominees: [
            {
                id: 5,
                nomineeShortCode: 'evma',
                imageSrc: null,
                nomineeName: 'Eva Martinez',
                designation: 'Tech Team Manager',
                about: 'Eva is a tech team manager at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/inspirational-leader',
                onVoteClick: () => console.log('Voted for Eva Martinez'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 6,
                imageSrc: null,
                nomineeShortCode: 'frro',
                nomineeName: 'Frank Rodriguez',
                designation: 'CTO',
                about: 'Frank is a CTO at a startup in Pune. He is passionate about building accessible and inclusive products. He is also a tech blogger and loves to share his knowledge with the community.',
                awardCategoryLink: '/award-category/inspirational-leader',
                onVoteClick: () => console.log('Voted for Frank Rodriguez'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            // Add more nominees...
        ],
    },
    {
        awardCategory: 'Innovative Startup',
        categoryShortCode: 'innovative-startup',
        nominees: [
            {
                id: 7,
                nomineeShortCode: 'grin',
                imageSrc: null,
                nomineeName: 'Grace Innovations',
                designation: 'Co-Founder',
                about: 'Grace Innovations is a co-founder at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/innovative-startup',
                onVoteClick: () => console.log('Voted for Grace Innovations'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 8,
                nomineeShortCode: 'hete',
                imageSrc: null,
                nomineeName: 'Henry Tech Solutions',
                designation: 'CEO',
                about: 'Henry Tech Solutions is a CEO at a startup in Pune. He is passionate about building accessible and inclusive products. He is also a tech blogger and loves to share his knowledge with the community.',
                awardCategoryLink: '/award-category/innovative-startup',
                onVoteClick: () => console.log('Voted for Henry Tech Solutions'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            // Add more nominees...
        ],
    },
    {
        awardCategory: 'Woman Founder',
        categoryShortCode: 'woman-founder',
        nominees: [
            {
                id: 9,
                nomineeShortCode: 'islo',
                imageSrc: null,
                nomineeName: 'Isabel Lopez',
                designation: 'Founder & CEO',
                about: 'Isabel is a founder & CEO at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/woman-founder',
                onVoteClick: () => console.log('Voted for Isabel Lopez'),
                linkedin: 'https://www.linkedin.com/in/isabellopez',
                twitter: 'https://www.twitter.com/isabellopez',
                github: 'https://www.github.com/isabellopez',
                website: 'https://www.isabellopez.com',
                youtube: 'https://www.youtube.com/isabellopez',
            },
            {
                id: 10,
                nomineeShortCode: 'jale',
                imageSrc: null,
                nomineeName: 'Jackie Lee',
                designation: 'Tech Entrepreneur',
                about: 'Jackie is a tech entrepreneur at a startup in Pune. She is passionate about building accessible and inclusive products. She is also a tech blogger and loves to share her knowledge with the community.',
                awardCategoryLink: '/award-category/woman-founder',
                onVoteClick: () => console.log('Voted for Jackie Lee'),
                linkedin: 'https://www.linkedin.com/in/jackielee',
                twitter: 'https://www.twitter.com/jackielee',
                github: 'https://www.github.com/jackielee',
                website: 'https://www.jackielee.com',
                youtube: 'https://www.youtube.com/jackielee',
            },
        ]
    }
];

export const categoryColors = {
    'Inspirational Leader': { color: '#4E4492', bg: 'rgba(78, 68, 146, 0.05)' },
    'Rising Star': { color: '#FA6164', bg: 'rgba(250, 97, 100, 0.05)' },
    'Community Contributor': { color: '#138086', bg: 'rgba(19, 128, 134, 0.05)' },
    'Innovative Startup': { color: '#F8D016', bg: 'rgba(248, 208, 22, 0.05)' },
    'Woman Founder': { color: '#B72552', bg: 'rgba(183, 37, 82, 0.05)' },
};

export const phase = new Date() > new Date('2023-12-23T00:00:00.000Z') ? 'voting' : 'nomination';

export const faqQuestions = [
    {
        question: 'Who can participate?',
        answer: 'People from all over India are eligible to participate. They must be available for the event if they are selected.',
    },
    {
        question: 'Is there a cost to apply for the award?',
        answer: 'No, there is no cost associated with participating and nominating in these awards.'
    },
    {
        question: 'How can I nominate myself?',
        answer: 'To nominate yourself, click on nominate yourself. Then, complete your public proﬁle with your description and send it to be reviewed by our specialists. We will check your information and let you know via email when your proﬁle is online.',
    },
    {
        question: 'How can I vote for someone?',
        answer: 'To vote for someone, you can go to the candidate section and see all the available proﬁles. Once you choose your candidate, open their proﬁle and ﬁll out the form next to their description. Remember, we only count one vote for each candidate you vote for.'
    },
    {
        question: 'What are the criteria for choosing the winners?',
        answer: `To choose the winners, we follow two approaches:\n
        1.	We respect the skills and experience criteria detailed for each category. You can learn more about it on the home page.\n
        2.	We debate and analyze candidates' stories to choose the ones that genuinely inspire the community and us.
        `
    },
    {
        question: 'How is the selection process?',
        answer: `We divide the selection process into 2 stages: \n
        Stage 1: We create a shortlist with the most voted candidates from all the categories. Each category gets 3 to 5 ﬁnalists.\n
        Stage 2: The core judging panel selects the winners. We choose two winners for each category.`,
    },
    {
        question: 'When does the selection process end? What happens next?',
        answer: 'The selection process ends on 22nd December 2023. After that, we will announce the winners at the Devfest Pune event on 23rd December 2023.',
    },
];
