import React from 'react'
import Header from '../shared/Header'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react'
import Footer from '../shared/Footer'
import { useNavigate } from 'react-router-dom'
import { awardCategories, faqQuestions } from '../../common/commonData'
import AwardCategoryCard from './AwardCategoryCard'

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Box marginY={10} className='about'>
        <Flex justifyContent={{ base: 'center', lg: 'space-between' }} flexWrap={{ base: 'wrap', md: 'nowrap' }} mb="4" marginX={{ base: 10, md: 200 }} alignItems='center'>
          <Box textAlign='center' width={{ base: 'fit-content', lg: '604px' }}>
            <Heading width='100%' as='h1' fontWeight={800} lineHeight={'145%'}>
              Inspire. Innovate. Ignite: Excellence Awards.
            </Heading>
            <Button onClick={() => {
              navigate('/nominate')
            }} colorScheme="messenger" size="lg" marginY={5}>
              Nominate Yourself
            </Button>
            {/* <Text>
            The Excellence Awards aim to honor the greatest solutions available now while also pushing the boundaries of what we think to be the critical components of a developer site for the future.
            </Text> */}
          </Box>
          <Image src={require('../../assets/images/Jan-Success_1 1.png')} alt='about' />
        </Flex>
      </Box>
      <Flex justifyContent='center' flexWrap='wrap' gap={10} alignItems='center' marginY={5} className='about'>
        <Heading as='h2' size='lg' mb='2' textAlign='center' width='100%'>
          Award Categories
        </Heading>
      </Flex>
      <Flex justifyContent='center' flexWrap='wrap' gap={10} alignItems='center' marginBottom={{ base: 5, md: 5 }} className='about'>
        {
          awardCategories.map((awardCategory, index) => (
            <AwardCategoryCard key={index} awardCategory={awardCategory} />
          ))
        }
      </Flex>
      <Box marginBottom={{base:20}} marginX={{ base: 10, md: 100 }}>
        <Heading as='h2' size='lg' mb='2' textAlign='center' marginY={5} width='100%'>F.A.Q</Heading>
        <Accordion allowToggle>
          {
            faqQuestions.map((faqQuestion, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box as='strong' flex="1" textAlign="left">
                      {faqQuestion.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel textAlign='left'>
                  <Text whiteSpace='pre-line' >
                    {faqQuestion.answer}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            ))
          }
        </Accordion>
      </Box>
      <Footer />
    </>
  )
}

export default Home
