import { Box, Button, Flex, Grid, GridItem, Icon, Image, Spinner, Text, useToast, } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { awardCategories } from '../../common/commonData'
import VotingRow from './VotingRow'
import VoterInfo from './VoterInfo'
import { FiGithub, FiGlobe, FiLinkedin, FiTwitter, FiYoutube } from 'react-icons/fi'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import axios from 'axios'
import { LinkIcon } from '@chakra-ui/icons'


const Profile = () => {
  const toast = useToast();
  const { category, person } = useParams();
  const [nomineesByCategory, setNomineesByCategory] = React.useState({});
  const [personData, setPersonData] = React.useState(null);
  const [nominationData, setNominationData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [isVoterInfoOpen, setVoterInfoOpen] = useState(false);

  const onViewAllClick = (shortcode) => {
    navigate(`/vote/${shortcode}`);
  }

  const navigate = useNavigate()
  const handleVoteBtn = () => {
    setVoterInfoOpen(true);
  };

  const closeVoterInfo = () => {
    setVoterInfoOpen(false);
  }

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const data = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/nomination/category-person`, {
          categoryShortcode: category,
          personShortcode: person
        });
        setPersonData(data.data.personData);
        setNominationData(data.data.nominationData);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.status === 404) {
            navigate('/404');
          }
          toast({
            title: error.response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        }
        console.log(error);
      }
    }
    fetchData();
  }, [category, person]
  )

  return (
    <>
      <Header />
      <Flex
        display={{ base: 'none', md: 'flex' }}
        justifyContent='center'
        marginTop='20px'
        margin={100}
        gap={4}
        boxShadow={'rgba(0, 0, 0, 0.35) 0px 5px 15px'}
        borderRadius='md'
        padding={10}
      >
        <Box
          backgroundRepeat='no-repeat'
          backgroundImage={require('../../assets/images/card-bg.png')} position="relative"
          display='flex'
          justifyContent='center'
          alignItems='end'
          minWidth="290px"
          minHeight="200px"
          maxWidth="290px"
          maxHeight="200px"
          textAlign="center"
        >
          <Image
            src={personData?.photo ? personData?.photo : require('../../assets/images/placeholder.jpg')}
            alt={personData?.firstName + " " + personData?.lastName}
            objectFit="cover"
            objectPosition="bottom"
            width="50%"
            height="80%"
            borderRadius="md"
            borderWidth={1}
            border={'solid white'}
          />
        </Box>
        <Flex
          direction='column'
          justifyContent='space-between'
          height="200px"
          textAlign="left"
          width='100%'
        >
          <Flex
            justifyContent='space-between'
            textAlign="center"
            width='100%'
          >
            <Box>
              <Text fontSize='2xl' fontWeight='bold' textAlign='left' paddingLeft='30px'>{personData?.firstName + " " + personData?.lastName}</Text>
              <Text textAlign='left' paddingLeft='30px' color='gray'> {personData?.designation + ", " + personData?.company}</Text>
            </Box>
            <Box>
              <Button
                colorScheme='blue'
                px='30px'
                onClick={handleVoteBtn}
              > Vote</Button>
            </Box>
          </Flex>
          <Box>
            <Text paddingLeft='30px'>
              {personData?.about}
            </Text>
          </Box>
          <Flex display='flex' justifyContent='flex-start' gap={5} paddingLeft='30px'>
            {personData?.linkedin && <LinkIcon href={personData?.linkedin} target='_blank' as={FiLinkedin} />}
            {personData?.github && <LinkIcon href={personData?.github} target='_blank' as={FiGithub} />}
            {personData?.twitter && <LinkIcon href={personData?.twitter} target='_blank' as={FiTwitter} />}
            {personData?.portfolio && <LinkIcon href={personData?.portfolio} target='_blank' as={FiGlobe} />}
            {personData?.youtube && <LinkIcon href={personData?.youtube} target='_blank' as={FiYoutube} />}
          </Flex>

        </Flex>

      </Flex>

      <Flex
        display={{ base: 'flex', md: 'none' }}
        direction='column'
        justifyContent='center'
        marginTop='20px'
        gap={4}
      >
        {/* <Image width='100%' src='images/dummy.png' /> */}
        <Box
          alignSelf='center'
          backgroundRepeat='no-repeat'
          backgroundImage={require('../../assets/images/card-bg.png')}
          position="relative"
          display='flex'
          justifyContent='center'
          alignItems='end'
          minWidth="290px"
          minHeight="200px"
          maxWidth="290px"
          maxHeight="200px"
          textAlign="center"
          width='100%'
        >
          <Image
            src={personData?.photo ? personData?.photo : require('../../assets/images/dummy.png')}
            alt={personData?.firstName + " " + personData?.lastName}
            objectFit="cover"
            objectPosition="bottom"
            width="50%"
            height="80%"
            borderRadius="md"
            borderWidth={1}
            border={'solid white'}
          />
        </Box>
        <Box>
          <Button
            colorScheme='blue'
            px='42%'
            marginTop='20px'
            onClick={handleVoteBtn}
          >Vote</Button>
        </Box>
        <Box >
          <Text paddingLeft='10px' fontSize='4xl' fontWeight='bold' textAlign='left'>{personData?.firstName + " " + personData?.lastName}</Text>
          <Text paddingLeft='10px' color='gray' textAlign='left'>{personData?.designation + ", " + personData?.company}</Text>
        </Box>
        <Box width='100%' >
          <Text padding='10px' textAlign='justify'>
            {personData?.about}
          </Text>
        </Box>
        <Box display='flex' justifyContent='space-around'>
          {personData?.linkedin && <LinkIcon href={personData?.linkedin} target='_blank' as={FiLinkedin} />}
          {personData?.github && <LinkIcon href={personData?.github} target='_blank' as={FiGithub} />}
          {personData?.twitter && <LinkIcon href={personData?.twitter} target='_blank' as={FiTwitter} />}
          {personData?.portfolio && <LinkIcon href={personData?.portfolio} target='_blank' as={FiGlobe} />}
          {personData?.youtube && <LinkIcon href={personData?.youtube} target='_blank' as={FiYoutube} />}
        </Box>
      </Flex>
      <VoterInfo isOpen={isVoterInfoOpen} onClose={closeVoterInfo} categoryId={nominationData?.categoryId} nominationId={nominationData?.id} />

      <Box marginTop={10} marginBottom={20}>
        {
          loading ? (
            <Flex justifyContent="center" alignItems="center" height="90vh">
              <Spinner size="xl" />
            </Flex>
          ) :
            awardCategories.map((awardCategory) => {
              return (
                <VotingRow shortcode={awardCategory.shortcode} awardCategory={awardCategory.name} nominees={nomineesByCategory[awardCategory.shortcode]} key={awardCategory.shortcode} onViewAllClick={onViewAllClick} />
              )
            })
        }
      </Box>
      <Footer />
    </>
  )
}

export default Profile

