import { Box, Flex, Heading, Image, Text, Link as ALink } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import React from 'react'
import { FiHome, FiInstagram, FiLinkedin, FiTwitter, FiYoutube } from 'react-icons/fi'
import { MdHowToVote } from "react-icons/md";
import { RiSurveyLine } from "react-icons/ri";
import { CiTrophy } from "react-icons/ci";
import { phase } from '../../common/commonData';



const Footer = () => {
    const location = useLocation();

    const isActive = (pathname) => location.pathname === pathname;
    return (
        <>
            <Flex
                backgroundColor='white'
                bottom='0'
                top='80vh'
                left='0'
                width='100%'
                boxShadow='md'
                direction={{ base: 'column', md: 'row' }}
                justifyContent={{ base: 'center', md: 'space-evenly' }}
                alignItems='center'
                display={{ base: 'none', md: 'flex' }}
                borderTop='1px solid #E2E8F0'
            >
                <Box margin='20px'>
                    <Image src={require('../../assets/images/gdg-logo.png')} />
                    <Text>Excellence Awards</Text>
                </Box>
                <Box margin='20px'>
                    <Flex
                        direction='column'
                        alignItems='flex-start'
                    >
                        <Heading as='h4'>About</Heading>
                        <ALink href='https://developers.google.com/community/devfest' >Devfest</ALink>
                        <ALink href='https://developers.google.com/community/gdg'>GDG Pune</ALink>
                        <ALink href='https://www.womentechmakers.com/'>WTM Program</ALink>
                        <ALink href='https://devfest.gdgpune.in/coc'>Code of Conduct</ALink>
                        <ALink href='https://developers.google.com/community-guidelines'>Community Guidelines</ALink>
                    </Flex>
                </Box>
                <Box margin='20px'>
                    <Flex
                        direction='column'
                        gap={5}
                    >
                        <Heading as='h4'>Follow Us</Heading>
                        <Flex justifyContent='space-evenly'>
                            <ALink href='https://www.instagram.com/gdgpune'>
                                <FiInstagram />
                            </ALink>
                            <ALink href='https://twitter.com/gdgpune'>
                                <FiTwitter />
                            </ALink>
                            <ALink href='https://www.linkedin.com/company/gdgpune/'>
                                <FiLinkedin />
                            </ALink>
                            <ALink href='https://www.youtube.com/channel/UCWjodTrsdRBOUyTuYo2l4Og'>
                                <FiYoutube />
                            </ALink>
                        </Flex>
                    </Flex>
                </Box>
            </Flex>
            <Flex
                backgroundColor='white'
                position='fixed'
                bottom='0'
                width='100%'
                boxShadow='md'
                padding='10px'
                justifyContent='space-around'
                alignItems='center'
                display={{ base: 'flex', md: 'none' }}
                borderTop='1px solid #E2E8F0'
            >
                <Link to='/'>
                    <Flex
                        direction='column'
                        alignItems='center'
                        color={isActive('/') ? 'black.500' : 'gray.500'}
                        fontWeight={isActive('/') ? 'bold' : 'normal'}
                    >
                        <FiHome />
                        Home
                    </Flex>
                </Link>
                {
                    phase === 'voting' &&
                    <Link to='/vote'>
                        <Flex
                            direction='column'
                            alignItems='center'
                            color={isActive('/vote') ? 'black.500' : 'gray.500'}
                            fontWeight={isActive('/vote') ? 'bold' : 'normal'}
                        >
                            <MdHowToVote />
                            Vote
                        </Flex>
                    </Link>
                }
                {
                    phase === 'nomination' &&
                    <Link to='/nominate'>
                        <Flex
                            direction='column'
                            alignItems='center'
                            color={isActive('/nominate') ? 'black.500' : 'gray.500'}
                            fontWeight={isActive('/nominate') ? 'bold' : 'normal'}
                        >
                            <RiSurveyLine />
                            Nominate
                        </Flex>
                    </Link>
                }
                <Link to='/results'>
                    <Flex
                        direction='column'
                        alignItems='center'
                        color={isActive('/results') ? 'black.500' : 'gray.500'}
                        fontWeight={isActive('/results') ? 'bold' : 'normal'}
                    >
                        <CiTrophy />
                        Results
                    </Flex>
                </Link>
            </Flex>
        </>

    )
}

export default Footer;
